import { makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import DatePicker from "../../../Common/DatePicker";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import TextField from "../../../Common/TextField";
import CancelButton from "../../../Common/CancelButton";
import SubmitButton from "../../../Common/SubmitButton";
import { SemesterAllocationInfo } from "./SemesterAllocationInfo";
import colors from "../../../../styles/colors";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import moveOutFormActions from "../../../../store/processes/relocation/moveOutForm/actions";
import loadingActions from "../../../../store/loading/actions";
import { history } from "../../../../store/index";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        '&:hover': {
            backgroundColor: colors.newHover,
        }
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 4px",
        fontWeight: "bold"
    },
}))

function MoveOutForm(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();

    const header = {
        title: "Kiköltözési nyilatkozat",
        breadcrumbs: {
            "process": "Folyamatok",
            "relocation": "Átköltözés",
            "move-out-form": "Kiköltözési nyilatkozat",
        }
    }

    useEffect(() => {
        props.loadingOn();
        props.get(id)
            .finally(() => props.loadingOff());
    }, [])

    const formProps = {
        form: form,
        changeform: props.changeForm
    }

    const handleApprove = () => {
        props.loadingOn();
        props.fillMoveOutForm(form, id, "/")
            .finally(() => props.loadingOff());
    };

    return (
        <FormPageTemplate
            header={header}
            form={formProps}
        >
            <TextField
                name="@type"
                label="Típus"
                value={form["@type"] === "Relocation" ? "Átköltözés" : "Ismeretlen"} //FIXME: hardcoded
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="lastName"
                label="Vezetéknév"
                value={form["lastName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="firstName"
                label="Keresztnév"
                value={form["firstName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="address"
                label="Állandó lakcím"
                value={createAddressStringFromObject(form["address"]) || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="email"
                label="E-mail cím"
                value={form["email"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="phoneNumber"
                label="Telefonszám"
                value={form["phoneNumber"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <SemesterAllocationInfo 
                form={form?.oldStudentAllocation}
                formProps={formProps}
                type="old"
            />
            <SemesterAllocationInfo 
                form={form?.newStudentAllocation}
                formProps={formProps}
                type="new"
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="moveOutDate"
                value={form?.moveOutDate || new Date(new Date().setHours(9, 0, 0, 0)).toISOString().slice(0, 16)}
                format={{ xs: 12 }}
                disabled
                showTime={true}
            />
            <Typography
                className={classes.acceptanceText}
            >
                Aláírásommal igazolom, hogy a szobát a fentieknek megfelelően az alábbi
                dátummal átadtam.
            </Typography>
            <DatePicker
                label="Nyilatkozat dátuma"
                name="roomHandOverDate"
                value={form?.roomHandOverDate || new Date().toISOString().slice(0, 10)}
                format={{ xs: 12 }}
                disabled
            />
            <SubmitButton
                className={classes.submit}
                onClick={handleApprove}
            >
                Jóváhagyás
            </SubmitButton>
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    )
}

function mapState(state) {
    const { form } = state.relocationMoveOutForm;
    return { form };
}

const actionCreators = {
    defaultForm: moveOutFormActions.defaultForm,
    changeForm: moveOutFormActions.changeForm,
    get: moveOutFormActions.getForm,
    fillMoveOutForm: moveOutFormActions.fillMoveOutForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
}

export default connect(mapState, actionCreators)(MoveOutForm);