import {
    history,
    templateChangeForm,
    templateDefaultForm,
    templateGet,
} from "../../..";
import terminationsService from "../../../../services/processes/termination";
import { types } from "./types";
import notificationTypes from "../../../notification/types";
import {
    roomCleaningOptions,
    roomKeyOptions,
} from "../../../../Components/Processes/Relocation/Forms/OperatorRoomTakeOver";
import { kitchenKeyOptions } from "../../../../Components/Processes/Termination/FinancialCheck";

function approveOperatorCheck(form, id, url) {
    const originalItems = form?.room?.items;
    let formItems = form.items || {};

    if (originalItems) {
        Object.entries(originalItems).forEach((item) => {
            const itemId = item[1]?.id;
            if (!formItems[itemId]) {
                formItems[itemId] = "0";
            }
        });
    }

    const formToSend = {
        terminationId: Number(id),
        roomCleaningStatus:
            form.roomCleaningStatus || roomCleaningOptions[0].value,
        roomKeyStatus: form.roomKeyStatus || roomKeyOptions[0].value,
        kitchenKeyStatus: form.kitchenKeyStatus || kitchenKeyOptions[0].value,
        items: formItems,
        operatorComment: form.operatorComment,
    };

    return (dispatch) => {
        dispatch({ type: types.APPROVE_OPERATOR_CHECK_REQUEST, formToSend });
        return terminationsService
            .approveOperatorCheck(formToSend, id)
            .then((data) => {
                dispatch({ type: types.APPROVE_OPERATOR_CHECK_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés!" },
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            });
    };
}

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return terminationsService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    approveOperatorCheck,
    get: templateGet(types, terminationsService),
    getForm
};

export default actions;
