import { Typography } from "@material-ui/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { history } from "../../../../store";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import terminationFinancialCheckActions from "../../../../store/processes/termination/financialCheck/actions";
import CancelButton from "../../../Common/CancelButton";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import Loading from "../../../Common/Loading";
import SubmitButton from "../../../Common/SubmitButton";
import TextField from "../../../Common/TextField";
import {
    roomCleaningOptions,
    roomKeyOptions,
} from "../../Relocation/Forms/OperatorRoomTakeOver";
import ReminderDialog from "../../Utils/ReminderDialog";
import RoomInventoryTable from "../../Utils/RoomInventoryTable";
import DepositRefundForm from "../StudentDeclaration/DepositRefundForm";
import DonationConsentForm from "../StudentDeclaration/DonationConsentForm";
import ReadOnlyForm from "../StudentDeclaration/ReadOnlyForm";
import {
    donatingDepositOptions,
    reasonsOfTerminationOptions,
    useStyles,
} from "../utils";

const header = (isMoveOutProcess) => {
    return {
        title: "Pénzügyi ellenőrzés",
        breadcrumbs: {
            process: "Folyamatok",
            relocation: isMoveOutProcess ? "Kiköltözés" : "Kiiratkozás",
            "financial-control": "Pénzügyi ellenőrzés",
        },
    };
};

export const kitchenKeyOptions = [
    { value: "ok", label: "Rendben" },
    { value: "damaged", label: "Sérült" },
    { value: "missing", label: "Hiányzik" },
];

function FinancialCheck(props) {
    const { form, reminderForm, emailTemplate } = props;
    const { id } = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.all([props.get(id), props.filterEmailTemplate()]).finally(() => {
            setLoading(false);
        });
    }, []);

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
    };

    const handleApprove = useCallback(() => {
        setLoading(true);
        props
            .approveFinancialCheck(form, id, "/")
            .finally(() => setLoading(false));
    }, [props, form, id]);

    const handleSendNotification = useCallback(() => {
        axios
            .all([
                props.updateFinancialComment(form, id),
                props.sendUnpaidDebtNotification(id, {
                    emailSubject: reminderForm.emailSubject,
                    emailContent: reminderForm.emailContent,
                }),
            ])
            .catch((error) => props.changeForm(
                {
                    ...form,
                    errors: error?.response?.data?.violations.reduce(
                        (obj, item) => ({...obj, [item?.propertyPath]: item?.message}), {})
                },
                "reminderForm"
            ))
            .finally(() => props.get(id))
    }, [props, form, id, reminderForm]);

    if (loading) {
        return <Loading />;
    }

    return (
        <FormPageTemplate
            header={header(form.isMoveOutProcess)}
            form={formProps}
        >
            <ReadOnlyForm form={form} formProps={formProps} />
            {form.currentMembershipStatus === "internal" && (
                <TextField
                    label="Kiköltözés oka"
                    name="reasonOfTermination"
                    value={
                        reasonsOfTerminationOptions.find(
                            (option) =>
                                option.value === form?.reasonOfTermination
                        )?.label || "-"
                    }
                    format={{ xs: 12 }}
                    disabled
                />
            )}
            {form.currentMembershipStatus === "internal" && (
                <TextField
                    label="Kiköltözés várható dátuma"
                    name="terminationDate"
                    value={form?.terminationDate ? new Date(form.terminationDate).toISOString().slice(0, 16).replace("T", " ") : "-"}
                    format={{ xs: 12 }}
                    disabled
                />
            )}
            {form.currentMembershipStatus === "internal" && (
                <TextField
                    label="Adományozási megállapodás"
                    name="donatingDeposit"
                    value={
                        donatingDepositOptions.find((option) => {
                            const donatingDepositText = form?.donatingDeposit
                                ? "true"
                                : "false";
                            return option.value === donatingDepositText;
                        })?.label || "-"
                    }
                    format={{ xs: 12 }}
                    disabled
                />
            )}
            {form.currentMembershipStatus === "internal" &&
                (form["donatingDeposit"] === false ? (
                    <DepositRefundForm
                        form={form}
                        classes={classes}
                        readOnly
                        format={{ xs: 12 }}
                        {...props}
                    />
                ) : (
                    <DonationConsentForm
                        classes={classes}
                        readOnly
                        form={form}
                        format={{ xs: 12 }}
                    />
                ))}
            {form.currentMembershipStatus === "internal" && (
                <Typography format={{ xs: 12 }} className={classes.smallTitle}>
                    Leltár
                </Typography>
            )}
            {form.currentMembershipStatus === "internal" && (
                <RoomInventoryTable
                    room={form?.room}
                    form={form}
                    changeForm={props.changeForm}
                    format={{ xs: 12 }}
                    readOnly
                />
            )}
            {form.currentMembershipStatus === "internal" && (
                <TextField
                    name="roomCleaningStatus"
                    value={
                        roomCleaningOptions.find(
                            (x) => x.value === form["roomCleaningStatus"]
                        )?.label || roomCleaningOptions[0].label
                    }
                    format={{ xs: 12 }}
                    label="Szobatakarítás"
                    disabled
                />
            )}
            {form.currentMembershipStatus === "internal" && (
                <>
                <TextField
                    name="roomKeyStatus"
                    value={roomKeyOptions.find(
                        (x) => x.value === form["roomKeyStatus"]
                    )?.label || roomKeyOptions[0].label}
                    format={{ xs: 12 }}
                    label="Szobakulcs"
                    disabled />
                <TextField
                    name="kitchenKeyStatus"
                    value={kitchenKeyOptions.find(
                        (x) => x.value === form["kitchenKeyStatus"]
                    )?.label || kitchenKeyOptions[0].label}
                    format={{ xs: 12 }}
                    label="Konyhaszekrény kulcs"
                    disabled />
                </>
            )}
            {form.currentMembershipStatus === "internal" && (
                <TextField
                    label="Üzemeltető megjegyzése"
                    name="operatorComment"
                    value={form["operatorComment"] || ""}
                    format={{ xs: 12 }}
                    multiline
                    rows={4}
                    disabled
                />
            )}

            {/* 
                TODO: form gyorsítása
            */}
            <TextField
                label="Megjegyzés"
                name="financialComment"
                value={form["financialComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                rules={{ length: 255 }}
            />
            <Typography className={classes.acceptanceText} format={{ xs: 12 }}>
                A pénzügyi adatokat ellenőriztem, az MCC felé tartozása nincsen.
            </Typography>
            <SubmitButton className={classes.submit} onClick={handleApprove}>
                Jóváhagyás
            </SubmitButton>
            <ReminderDialog
                reminderForm={reminderForm}
                emailTemplate={emailTemplate}
                handleSendNotification={handleSendNotification}
                changeForm={props.changeForm}
                changeEditor={props.changeEditor}
            />
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form, reminderForm } = state.terminationFinancialCheck;
    const emailTemplate = state.emailTemplates.data["hydra:member"]
        ? state.emailTemplates.data["hydra:member"]?.find(
              (element) => element["key"] === "termination_unpaid_debt"
          )
        : {};
    return { form, reminderForm, emailTemplate };
}

const actionCreators = {
    changeForm: terminationFinancialCheckActions.changeForm,
    defaulFrom: terminationFinancialCheckActions.defaultForm,
    changeEditor: terminationFinancialCheckActions.changeEditor,
    get: terminationFinancialCheckActions.getForm,
    sendUnpaidDebtNotification:
        terminationFinancialCheckActions.sendUnpaidDebtNotification,
    approveFinancialCheck:
        terminationFinancialCheckActions.approveFinancialCheck,
    updateFinancialComment:
        terminationFinancialCheckActions.updateFinancialComment,
    filterEmailTemplate: () =>
        emailTemplatesActions.filter({ key: "termination_unpaid_debt" }),
};

export default connect(mapState, actionCreators)(FinancialCheck);
