import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import DatePicker from "../../../Common/DatePicker";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import TextField from "../../../Common/TextField";
import CancelButton from "../../../Common/CancelButton";
import { SemesterAllocationInfo } from "./SemesterAllocationInfo";
import approveRoomTakeOverActions from "../../../../store/processes/relocation/approveRoomTakeOver/actions";
import loadingActions from "../../../../store/loading/actions";
import { connect } from "react-redux";
import SubmitButton from "../../../Common/SubmitButton";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import colors from "../../../../styles/colors";
import { history } from "../../../../store/index";
import Dialog from "../../../Common/Dialog";
import { kitchenKeyOptions } from "../../Termination/FinancialCheck";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
    title: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue,
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 4px",
        fontWeight: "bold",
    },
    tableCell: {
        fontSize: "12px",
        color: "#0000008A",
        fontWeight: "600",
        whiteSpace: "nowrap",
    },
    headerCell: {
        fontSize: "12px",
        color: colors.primaryBlue,
        fontWeight: "1000",
        whiteSpace: "nowrap",
    },
    container: {
        background: "rgba(0,0,0,0.1)",
    },
    titleText: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify",
    },
}));

/**
 * Warning: ezeket használja a operatorRoomTakeOverActions is form küldésnél
 * Ha nincs megadva érték veszi a [0] elem value property-jét
 */
export const roomCleaningOptions = [
    { value: "clean", label: "Nem szükséges" },
    { value: "need_cleaning", label: "Takarítási pótdíj" },
];

export const roomKeyOptions = [
    { value: "ok", label: "Rendben" },
    { value: "damaged", label: "Sérült" },
    { value: "missing", label: "Hiányzik" },
];

function ApproveRoomTakeOver(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();

    const header = {
        title: "Üzemeltetői ellenőrzés",
        breadcrumbs: {
            process: "Folyamatok",
            relocation: "Átköltözés",
            "operator-room-take-over": "Üzemeltetői ellenőrzés",
        },
    };

    useEffect(() => {
        props.loadingOn();
        props.get(id).finally(() => props.loadingOff());
    }, []);

    const formProps = {
        form: form,
        changeform: props.changeForm,
    };

    const handleApprove = () => {
        props.loadingOn();
        props
            .approveTakeOverRoom(form, id, "/")
            .finally(() => props.loadingOff());
    };

    return (
        <FormPageTemplate header={header} form={formProps}>
            <TextField
                name="@type"
                label="Típus"
                value={
                    form["@type"] === "Relocation" ? "Átköltözés" : "Ismeretlen"
                } //FIXME: hardcoded
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="lastName"
                label="Vezetéknév"
                value={form["lastName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="firstName"
                label="Keresztnév"
                value={form["firstName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="address"
                label="Állandó lakcím"
                value={createAddressStringFromObject(form["address"]) || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="email"
                label="E-mail cím"
                value={form["email"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="phoneNumber"
                label="Telefonszám"
                value={form["phoneNumber"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <SemesterAllocationInfo
                form={form?.oldStudentAllocation}
                formProps={formProps}
                type="old"
            />
            <SemesterAllocationInfo
                form={form?.newStudentAllocation}
                formProps={formProps}
                type="new"
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="moveOutDate"
                value={form?.moveOutDate}
                format={{ xs: 12 }}
                disabled
                showTime={true}
            />
            <TextField
                label="Pénzügyes megjegyzése"
                name="financialComment"
                value={form["financialComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                disabled
            />
            <TextField
                label="Üzemeltető megjegyzése"
                name="operatorComment"
                value={form["operatorComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                disabled
            />
            {ApproveRoomTakeOverDialog(
                classes,
                handleApprove,
                form,
                roomCleaningOptions,
                roomKeyOptions
            )}
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function ApproveRoomTakeOverDialog(
    classes,
    handleApprove,
    form,
    roomCleaningOptions,
    roomKeyOptions
) {
    return (
        <Dialog
            title="Ellenőrzés jóváhagyása"
            opener={
                <SubmitButton
                    className={classes.submit}
                    type="button"
                    fullWidth
                >
                    Jóváhagyás
                </SubmitButton>
            }
            action={
                <SubmitButton onClick={handleApprove}>Jóváhagyás</SubmitButton>
            }
            cancelText="Mégsem"
        >
            <Typography>
                <Paper className={classes.container} elevation={4}>
                    <Grid
                        container
                        className={classes.textContainer}
                        justify="center"
                    >
                        <Grid item xs={12} container justify="center">
                            <div className={classes.title}>
                                Biztosan jóváhagyod az átköltözést?
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                <strong>A régi szoba adatai:</strong>
                            </div>
                            <div className={classes.text}>
                                Campus:{" "}
                                {
                                    form?.oldStudentAllocation?.room?.floor
                                        ?.building?.campus?.name
                                }{" "}
                                <br />
                                Épület:{" "}
                                {
                                    form?.oldStudentAllocation?.room?.floor
                                        ?.building?.name
                                }{" "}
                                <br />
                                Szint:{" "}
                                {
                                    form?.oldStudentAllocation?.room?.floor
                                        ?.name
                                }{" "}
                                <br />
                                Szoba: {
                                    form?.oldStudentAllocation?.room?.name
                                }{" "}
                                <br />
                            </div>
                            <div className={classes.text}>
                                <strong>Tárgyak és takarítás:</strong>
                            </div>
                            <div className={classes.text}>
                                {form?.oldStudentAllocation?.room?.items?.map(
                                    (item) => {
                                        return (
                                            <div>
                                                {item?.name}:{" "}
                                                {form.handedOverItems?.find(
                                                    (x) =>
                                                        x.item?.id === item?.id
                                                )?.amount || 0}
                                                /{item?.amount}
                                            </div>
                                        );
                                    }
                                )}
                                Szobatakarítás:{" "}
                                {roomCleaningOptions.find(
                                    (x) =>
                                        x.value === form["roomCleaningStatus"]
                                )?.label || "Nem szükséges"}{" "}
                                <br />
                                Szobakulcs:{" "}
                                {roomKeyOptions.find(
                                    (x) => x.value === form["roomKeyStatus"]
                                )?.label || "Rendben"}
                                Konyhaszekrény kulcs:{" "}
                                {kitchenKeyOptions.find(
                                    (x) => x.value === form["kitchenKeyStatus"]
                                )?.label || "Rendben"}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                <strong>Az új szoba adatai:</strong>
                            </div>
                            <div className={classes.text}>
                                Campus:{" "}
                                {
                                    form?.newStudentAllocation?.room?.floor
                                        ?.building?.campus?.name
                                }{" "}
                                <br />
                                Épület:{" "}
                                {
                                    form?.newStudentAllocation?.room?.floor
                                        ?.building?.name
                                }{" "}
                                <br />
                                Szint:{" "}
                                {
                                    form?.newStudentAllocation?.room?.floor
                                        ?.name
                                }{" "}
                                <br />
                                Szoba: {
                                    form?.newStudentAllocation?.room?.name
                                }{" "}
                                <br />
                            </div>
                            <div className={classes.text}>
                                <strong>Tárgyak:</strong>
                            </div>
                            <div className={classes.text}>
                                {form?.takenOverItems?.map((item) => {
                                    return (
                                        <div>
                                            {item?.name}: {item?.amount}
                                        </div>
                                    );
                                })}
                            </div>
                        </Grid>
                        {form?.financialStatus === "unpaid" && (
                            <Grid item xs={12} container justify="center">
                                <div className={classes.text}>
                                    <strong>
                                        Mivel a hallgatónak van fennálló
                                        tartozása a kollégium felé, ezért ez a
                                        folyamat jóváhagyás után visszakerül
                                        pénzügyi ellenőrzésre.
                                    </strong>
                                </div>
                            </Grid>
                        )}
                        {form?.financialStatus === "ok" && (
                            <Grid item xs={12} container justify="center">
                                <div className={classes.text}>
                                    <strong>
                                        Mivel a hallgatónak nincs fennálló
                                        tartozása a kollégium felé, ezért ez a
                                        folyamat jóváhagyás után lezárásra
                                        kerül.
                                    </strong>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Typography>
        </Dialog>
    );
}

function mapState(state) {
    const { form } = state.relocationApproveRoomTakeOver;
    return { form };
}

const actionCreators = {
    defaultForm: approveRoomTakeOverActions.defaultForm,
    get: approveRoomTakeOverActions.getForm,
    approveTakeOverRoom: approveRoomTakeOverActions.approveTakeOverRoom,
    changeForm: approveRoomTakeOverActions.changeForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(ApproveRoomTakeOver);
