import { KeyboardDateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import FormikDatePicker from "./FormikDatePicker.js";
import { useEffect } from "react";

const CssKeyboardDatePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.primaryBlue,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&:hover fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.primaryBlue,
            },
        },
    },
})(KeyboardDatePicker);

const CssKeyboardDateTimePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.primaryBlue,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&:hover fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.primaryBlue,
            },
        },
    },
})(KeyboardDateTimePicker);


export default function DatePicker(props) {
    const { functionToChild, formNameToChild, formToChild, showTime = false, ...rest } = props;

    useEffect(() => {
        if (props.value === "Invalid-Date") {
            functionToChild({
                ...formToChild,
                ...(props.name.includes(".")
                    ? {
                          [props.name.split(".")[0]]: {
                              ...formToChild[props.name.split(".")[0]],
                              [props.name.split(".")[1]]: null,
                          },
                      }
                    : { [props.name]: null }),
            }, formNameToChild);
        }
    }, []);

    const convertToHyphenFormat = (date) => {
        if (!date) {
            return null;
        }
        return new Date(date).toISOString();
    };

    if (props.isFormikForm !== undefined && props.isFormikForm === true) {
        return <FormikDatePicker {...props} />;
    }

    const PickerComponent = showTime ? CssKeyboardDateTimePicker : CssKeyboardDatePicker;

    return (
        <PickerComponent
            {...rest}
            autoOk
            value={props.value || null}
            variant="inline"
            inputVariant="outlined"
            hideTabs={showTime ? true : false}
            format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
            minutesStep={showTime ? 15 : 1}
            invalidDateMessage={`Hibás formátum`}
            maxDateMessage={`Nem lehet nagyobb, mint 2300.01.01.`}
            minDateMessage={`Nem lehet kisebb, mint 1800.01.01.`}
            minDate={moment("1800-01-01")}
            maxDate={moment("2300-01-01")}
            InputLabelProps={{ shrink: true }}
            onChange={(date) =>
                functionToChild(
                    {
                        ...formToChild,
                        ...(props.name.includes(".")
                            ? {
                                  [props.name.split(".")[0]]: {
                                      ...formToChild[props.name.split(".")[0]],
                                      [props.name.split(".")[1]]: convertToHyphenFormat(date),
                                  },
                              }
                            : { [props.name]: convertToHyphenFormat(date) }),
                    },
                    formNameToChild
                )
            }
            fullWidth
            size="small"
        />
    );
}
