import { connect } from "react-redux";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import TextField from "../../../Common/TextField";
import loadingActions from "../../../../store/loading/actions";
import relocationFinancialControlActions from "../../../../store/processes/relocation/financialControl/actions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import { SemesterAllocationInfo } from "./SemesterAllocationInfo";
import DatePicker from "../../../Common/DatePicker";
import CancelButton from "../../../Common/CancelButton";
import SubmitButton from "../../../Common/SubmitButton";
import { makeStyles, Typography } from "@material-ui/core";
import colors from "../../../../styles/colors";
import emailTemplatesActions from "../../../../store/administration/emailTemplates/actions";
import { history } from "../../../../store/index";
import axios from "axios";
import Form from "../../../Common/Form";
import Dialog from "../../../Common/Dialog";
import CkEditor from "../../../Common/CkEditor";
import Title from "../../../Common/Title";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        '&:hover': {
            backgroundColor: colors.newHover,
        }
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 0px",
        fontWeight: "bold"
    },
    reminder: {
        backgroundColor: colors.reminder,
        '&:hover': {
            backgroundColor: colors.reminderHover,
        }
    }
}))

function FinancialControl(props) {
    const { form, reminderForm, emailTemplate } = props;
    const { id } = useParams();
    const classes = useStyles();

    const header = {
        title: "Pénzügyi ellenőrzés",
        breadcrumbs: {
            "process": "Folyamatok",
            "relocation": "Átköltözés",
            "financial-control": "Pénzügyi ellenőrzés",
        }
    }

    useEffect(() => {
        props.loadingOn();
        axios.all([
            props.get(id),
            props.filterEmailTemplate(),
        ])
            .finally(() => props.loadingOff());
    }, [])

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm
    }

    const reminderFormProps = {
        form: reminderForm,
        name: "reminderForm",
        changeform: props.changeForm
    }

    const handleApprove = () => {
        props.loadingOn();
        props.approveFinancialCheck(form, id, "/")
            .finally(() => props.loadingOff())
    };

    const handleSendNotification = () => {
        axios.all([
            props.updateFinancialComment(form, id),
            props.sendUnpaidDebtNotification(id, {
                emailSubject: reminderForm.emailSubject,
                emailContent: reminderForm.emailContent
            })
        ])
        .then(() => {
            props.loadingOn();
        })
        .catch((error) => props.changeForm(
            {
                ...form,
                errors: error?.response?.data?.violations.reduce(
                    (obj, item) => ({...obj, [item?.propertyPath]: item?.message}), {})
            },
            "reminderForm"
        ))
        .finally(() => {
            props.loadingOff();
        })
        .finally(() => props.get(id))
    };

    return (
        <FormPageTemplate
            header={header}
            form={formProps}
        >
            <TextField
                name="@type"
                label="Típus"
                value={form["@type"] === "Relocation" ? "Átköltözés" : "Ismeretlen"} //FIXME: hardcoded
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="lastName"
                label="Vezetéknév"
                value={form["lastName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="firstName"
                label="Keresztnév"
                value={form["firstName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="address"
                label="Állandó lakcím"
                value={createAddressStringFromObject(form["address"]) || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="email"
                label="E-mail cím"
                value={form["email"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="phoneNumber"
                label="Telefonszám"
                value={form["phoneNumber"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <SemesterAllocationInfo 
                form={form?.oldStudentAllocation}
                formProps={formProps}
                type="old"
            />
            <SemesterAllocationInfo 
                form={form?.newStudentAllocation}
                formProps={formProps}
                type="new"
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="moveOutDate"
                value={form?.moveOutDate}
                format={{ xs: 12 }}
                disabled //FIXME: ezt elszűrkíteni, ha disabled
                showTime={true}
            />
            <Typography
                className={classes.acceptanceText}
            >
                A pénzügyi adatokat ellenőriztem, az MCC felé tartozása nincsen.
            </Typography>
            <TextField
                label="Megjegyzés"
                name="financialComment"
                value={form["financialComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                rules={{ length: 255 }}
            />
            <SubmitButton
                className={classes.submit}
                onClick={handleApprove}
            >
                Jóváhagyás
            </SubmitButton>
            <Dialog
                opener={
                    <SubmitButton
                        className={classes.reminder}
                    >
                        Emlékeztető
                    </SubmitButton>
                }
                title="Emlékeztető"
                cancelText="Mégsem"
                action={
                    <SubmitButton
                        onClick={handleSendNotification}
                        preventClose={true}
                    >
                        Küldés
                    </SubmitButton>
                }
            >
                <Form
                    {...reminderFormProps}
                    childComponent="true"
                >
                    <Title
                        {...reminderFormProps}
                        title="E-mail"
                        format={{ xs: 12 }}
                        error={reminderForm.errors?.emailContent}
                    >
                        <TextField
                            label="Tárgy"
                            name="emailSubject"
                            value={reminderForm.emailSubject || ""}
                            format={{ xs: 12 }}
                            error={reminderForm.errors?.emailSubject}
                            helperText={reminderForm.errors?.emailSubject}
                        />
                        <CkEditor
                            type="editor"
                            name="emailContent"
                            data={reminderForm.emailContent || ""}
                            format={{ xs: 12 }}
                            onChange={(_, editor) => props.changeEditor(editor.getData())}
                        />
                    </Title>
                    <SubmitButton
                        name="insert"
                        type="button"
                        onClick={() => props.changeForm({
                            ...reminderForm,
                            emailSubject: emailTemplate.subject,
                            emailContent: emailTemplate.content
                        }, "reminderForm")
                        }
                        format={{ xs: 12 }}
                        className={classes.insert}
                    >
                        E-mail sablon beillesztése
                    </SubmitButton>
                </Form>
            </Dialog>
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    )
}

function mapState(state) {
    const { form, reminderForm } = state.relocationFinancialControl;
    const emailTemplate = state.emailTemplates.data["hydra:member"] ? state.emailTemplates.data["hydra:member"]?.find(element => element["key"] === 'relocation_unpaid_debt') : {};
    return { form, reminderForm, emailTemplate };
}

const actionCreators = {
    changeForm: relocationFinancialControlActions.changeForm,
    defaultForm: relocationFinancialControlActions.defaultForm,
    approveFinancialCheck: relocationFinancialControlActions.approveFinancialCheck,
    sendUnpaidDebtNotification: relocationFinancialControlActions.sendUnpaidDebtNotification,
    get: relocationFinancialControlActions.getForm,
    updateFinancialComment: relocationFinancialControlActions.updateFinancialComment,
    changeEditor: relocationFinancialControlActions.changeEditor,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    filterEmailTemplate: () => emailTemplatesActions.filter({ key: "relocation_unpaid_debt" })
}


export default connect(mapState, actionCreators)(FinancialControl);