import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { history } from "../../../../store";
import terminationOperatorCheckActions from "../../../../store/processes/termination/operatorCheck/actions";
import CancelButton from "../../../Common/CancelButton";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import Loading from "../../../Common/Loading";
import Select from "../../../Common/Select";
import SubmitButton from "../../../Common/SubmitButton";
import TextField from "../../../Common/TextField";
import {
    roomCleaningOptions,
    roomKeyOptions,
} from "../../Relocation/Forms/OperatorRoomTakeOver";
import RoomInventoryTable from "../../Utils/RoomInventoryTable";
import DepositRefundForm from "../StudentDeclaration/DepositRefundForm";
import DonationConsentForm from "../StudentDeclaration/DonationConsentForm";
import ReadOnlyForm from "../StudentDeclaration/ReadOnlyForm";
import {
    donatingDepositOptions,
    reasonsOfTerminationOptions,
    useStyles,
} from "../utils";
import { kitchenKeyOptions } from "../FinancialCheck";

const header = (isMoveOutProcess) => {
    return {
        title: "Üzemeltetési ellenőrzés",
        breadcrumbs: {
            processes: "Folyamatok",
            termination: isMoveOutProcess ? "Kiköltözés" : "Kiiratkozás",
            "student-declaration": "Üzemeltetési ellenőrzés",
        },
    };
};

function OperatorCheck(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    // FIXME: Ezt nem használjuk:
    const validationSchema = yup.object().shape({
        moveOutDate_: yup.date().required("Kötelező megadni"),
    });

    useEffect(() => {
        setLoading(true);
        props.get(id).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading />;
    }

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultForm: props.defaultForm,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        validationschema: validationSchema,
        onsubmit: () => props.approveOperatorCheck(form, id, "/"),
        isformikform: true,
    };

    return (
        <FormPageTemplate
            header={header(form.isMoveOutProcess)}
            form={formProps}
        >
            <ReadOnlyForm form={form} formProps={formProps} />
            <TextField
                label="Kiköltözés oka"
                name="reasonOfTermination"
                value={
                    reasonsOfTerminationOptions.find(
                        (option) => option.value === form?.reasonOfTermination
                    )?.label || "-"
                }
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                label="Kiköltözés várható dátuma"
                name="terminationDate"
                value={form?.terminationDate ? new Date(form.terminationDate).toISOString().slice(0, 16).replace("T", " ") : "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                label="Adományozási megállapodás"
                name="donatingDeposit"
                value={
                    donatingDepositOptions.find((option) => {
                        const donatingDepositText = form?.donatingDeposit
                            ? "true"
                            : "false";
                        return option.value === donatingDepositText;
                    })?.label || "-"
                }
                format={{ xs: 12 }}
                disabled
            />
            {form["donatingDeposit"] === false ? (
                <DepositRefundForm
                    form={form}
                    classes={classes}
                    readOnly
                    format={{ xs: 12 }}
                    {...props}
                />
            ) : (
                <DonationConsentForm
                    classes={classes}
                    readOnly
                    form={form}
                    format={{ xs: 12 }}
                />
            )}
            <Typography format={{ xs: 12 }} className={classes.title}>
                Átadás műszaki ellenőrzése
            </Typography>
            <Typography format={{ xs: 12 }} className={classes.smallTitle}>
                Leltár
            </Typography>
            <RoomInventoryTable
                room={form?.room}
                form={form}
                changeForm={props.changeForm}
                format={{ xs: 12 }}
            />
            <Select
                name="roomCleaningStatus"
                value={
                    form["roomCleaningStatus"] || roomCleaningOptions[0].value
                }
                optionList={roomCleaningOptions}
                format={{ xs: 12 }}
                selectLabel="Szobatakarítás"
            />
            <Select
                name="roomKeyStatus"
                value={form["roomKeyStatus"] || roomKeyOptions[0].value}
                optionList={roomKeyOptions}
                format={{ xs: 12 }}
                selectLabel="Szobakulcs"
            />
            <Select
                name="kitchenKeyStatus"
                value={form["kitchenKeyStatus"] || kitchenKeyOptions[0].value}
                optionList={kitchenKeyOptions}
                format={{ xs: 12 }}
                selectLabel="Konyhaszekrény kulcs"
                />
            {/* 
                TODO: form gyorsítása
                TODO: jóváhagyó ablak
            */}
            <TextField
                label="Megjegyzés"
                name="operatorComment"
                value={form["operatorComment"] || ""}
                format={{ xs: 12 }}
                multiline
                rows={4}
                rules={{ length: 255 }}
            />
            <SubmitButton className={classes.submit} type="submit">
                Jóváhagyás
            </SubmitButton>
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form } = state.terminationOperatorCheck;
    return { form };
}

const actionCreators = {
    changeForm: terminationOperatorCheckActions.changeForm,
    defaulFrom: terminationOperatorCheckActions.defaultForm,
    get: terminationOperatorCheckActions.getForm,
    approveOperatorCheck: terminationOperatorCheckActions.approveOperatorCheck,
};

export default connect(mapState, actionCreators)(OperatorCheck);
