import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { history } from "../../../../store/index";
import loadingActions from "../../../../store/loading/actions";
import relocationStudentFillingActions from "../../../../store/processes/relocation/studentFilling/actions";
import colors from "../../../../styles/colors";
import { createAddressStringFromObject } from "../../../../utils/AppConst";
import CancelButton from "../../../Common/CancelButton";
import DatePicker from "../../../Common/DatePicker";
import FormPageTemplate from "../../../Common/FormPageTemplate";
import PhoneInputField from "../../../Common/PhoneInputField";
import SubmitButton from "../../../Common/SubmitButton";
import TextField from "../../../Common/TextField";
import MoveOutDeclaration from "../../Utils/MoveOutDeclaration";
import { SemesterAllocationInfo } from "./SemesterAllocationInfo";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 0px",
        fontWeight: "bold",
    },
    container: {
        background: "rgba(0,0,0,0.1)",
    },
    title: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify",
    },
}));

function StudentFilling(props) {
    const { form } = props;
    const { id } = useParams();
    const classes = useStyles();

    const header = {
        title: "Átköltözési adatlap",
        breadcrumbs: {
            "student-filling": "Átköltözési adatlap",
        },
    };

    // FIXME: Ezt nem használjuk:
    const validationSchema = yup.object().shape({
        moveOutDate_: yup.date().required("Kötelező megadni"),
    });

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultForm: props.defaultForm,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        validationschema: validationSchema,
        onsubmit: () => props.fillStudentForm(form, id, "/"),
        isformikform: true,
    };

    useEffect(() => {
        props.loadingOn();
        props.get(id).finally(() => {
            props.loadingOff();
        });
    }, []);

    return (
        <FormPageTemplate header={header} form={formProps}>
            <MoveOutDeclaration />
            <TextField
                name="@type"
                label="Típus"
                value={"Átköltözés"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="lastName"
                label="Vezetéknév"
                value={form["lastName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="firstName"
                label="Keresztnév"
                value={form["firstName"] || "-"}
                format={{ xs: 12, md: 6 }}
                disabled
            />
            <TextField
                name="address"
                label="Állandó lakcím"
                value={createAddressStringFromObject(form["address"]) || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <TextField
                name="email"
                label="E-mail cím"
                value={form["email"] || "-"}
                format={{ xs: 12 }}
                disabled
            />
            <PhoneInputField
                name="phoneNumber"
                label="Telefonszám"
                value={form["phoneNumber"] || "-"}
                format={{ xs: 12 }}
            />
            <SemesterAllocationInfo
                form={form?.oldStudentAllocation}
                formProps={formProps}
                type="old"
            />
            <SemesterAllocationInfo
                form={form?.newStudentAllocation}
                formProps={formProps}
                type="new"
            />
            <DatePicker
                label="Kiköltözés várható dátuma"
                name="moveOutDate"
                value={
                    form?.moveOutDate || new Date(new Date().setHours(9, 0, 0, 0)).toISOString().slice(0, 16)
                }
                format={{ xs: 12 }}
                showTime={true}
            />
            <Typography className={classes.acceptanceText}>
                Kijelentem, hogy a fenti időpontban a szobát kitakarítva, a
                szobában található berendezési tárgyakkal együtt hiánytalanul
                átadom, valamint az átvett szobakulcsokat leadom. Tudomásul
                veszem, hogy a Collegiumban hagyott tárgyaimért, a Collegium
                felelősséget nem vállal.
            </Typography>
            <SubmitButton className={classes.submit} type="submit">
                Adatlap beküldése
            </SubmitButton>
            <CancelButton onClick={() => history.push("/")}>
                Mégsem
            </CancelButton>
        </FormPageTemplate>
    );
}

function mapState(state) {
    const { form } = state.relocationStudentFilling;
    return { form };
}

const actionCreators = {
    changeForm: relocationStudentFillingActions.changeForm,
    defaulFrom: relocationStudentFillingActions.defaultForm,
    get: relocationStudentFillingActions.getForm,
    fillStudentForm: relocationStudentFillingActions.fillStudentForm,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

export default connect(mapState, actionCreators)(StudentFilling);
